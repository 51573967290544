import { useApisNotEnough, useRidingApi } from "../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "dark",
        type: "dark"
      },
      title: "Insights",
      uri: "/insights/",
      slug: "insights",
      seo: {
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Insights - Vivid",
        opengraphType: "article",
        title: "Insights - Vivid",
      },
      flexchildren: [
        {
          __typename: "PageHeader",
          description:
            "From that aha moment, to the ideas we love, and innovations that excite us.",
          heading: "Insights worth sharing",
        },
        {
          __typename: "GridCard",
          items: [
            {
              __typename: "WpInsight",
              title: "APIs are not enough",
              uri: "/insights/apis-are-not-enough/",
              baseFields: {
                previewDescription: "Adopting a new paradigm for success. ",
                previewImage: {
                  altText: "v4",
                  useImage: useApisNotEnough,
                },
              },
            },
            {
              __typename: "WpInsight",
              title: "Riding the API wave",
              uri: "/insights/riding-the-api-wave/",
              baseFields: {
                previewDescription:
                  "The new economic reality demands efficient interconnectivity, and companies are racing to build APIs to adapt. ",
                previewImage: {
                  altText: "Featured-Idea-2",
                  useImage: useRidingApi,
                },
              },
            },
          ],
          callToAction: {
            buttonLink: {
              target: "",
              title: "Get in touch",
              url: "https://wp.Vivid.net/contact/",
            },
            description:
              "If you have an insight or idea worth sharing, reach out and let us know.",
            title: "Share your insights",
          },
        },
      ],
    },
  },
};
